.App {
    padding: 0px !important;
    color: rgba(0,0,0,.7) !important;
}
.servicesHeader, .textContainer .title{
    color: rgb(20,59,23) !important;
}

.footerDiv {
    background-color: rgb(20,59,23) !important;
}

.logoDiv {
    display: flex;
}

.footerDiv h5 {
    color: white;
}
.imageContainer {
    border-color: rgb(20,59,23) !important;
}
.imageContainer img {
    width: 48px !important;
    height: 48px !important;
}

.mainIMG {
    border: 2px solid rgb(20,59,23);
    margin: -130px 30px 0px 30px;
    max-width: calc(100% - 60px);
}
.mainImgSection {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.sectionHeader {
    color: rgb(20,59,23);
    font-size: 3rem;
    font-weight: 900;
    -webkit-letter-spacing: 0.025em;
    -moz-letter-spacing: 0.025em;
    -ms-letter-spacing: 0.025em;
    letter-spacing: 0.025em;
    text-align: center;
    margin: 30px 0px;
}
.navLinks {
    display: flex;
    margin-left: auto;
    width: 235px;
}
.navLinks a{
    color: white;
    margin: auto 8px;
    min-width: 150px;
    font-size: 24px;
    text-align: center;
}

.phoneIcon {
    height: 32px;
    margin: auto 0px;
}

.textIcon {
    height: 32px;
}

header {
    display: flex;
    background-color: rgb(20,59,23);
    padding: 2rem 2rem 10rem 2rem;
}
h1 {
    font-weight: 900;
    font-size: 34px;
    color: white;
}
.aboutSection {
    padding-left: 2rem;
    padding-right: 2rem;
}
.aboutSection h3 {
    color: rgb(20,59,23);
    font-weight: 900;
    font-size: 30px;
}
.aboutSection p {
    margin-bottom: 30px;
    font-size: 22px;
}
.upTree1 {
    max-width: calc(100% - 30px);
    border: 2px solid rgb(20,59,23);
    margin: 0px auto 30px auto;
}
.after1 {
    max-width: calc(100% - 30px);
    border: 2px solid rgb(20,59,23);
    margin: 0px auto;
}
.aboutSecion img {
    margin-left: auto;
    margin-right: auto;
}

.logoIcon {
    width: 3.5rem;
    max-height: 56px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.75rem;
}
.contactSection {
    padding: 0px 2rem 2rem;
}
.brayanImg {
    max-width: 350px;
    width: auto;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
}
@media (max-width: 750px) {
    header {
        display: block;
    }
    .brayanImg {
        max-width: 150px;
        max-height: 200px;
    }
}
.contactDiv {
    display: flex;
}

.contactSection h3 {
    color: rgb(20,59,23);
    font-weight: 900;
    font-size: 30px;
}
.contactInfoDiv {
    margin-left: 20px;
    font-size: 22px;
}

.contactInfoDiv a {
    text-decoration: underline;
}
div {
    border-color:  rgb(20,59,23) !important;
}

.pdfInput {
    border: 1px solid #d2d2d2;
    padding: 8px 15px;
    width: 100%;
    max-width: 100%;
}

.checkbox {
    border: 1px solid #d2d2d2;
}

.inputLabel {
    display: block;
}

.inputDiv {
    margin-bottom: 20px;
}

.errorDiv input, .errorDiv .pdfSelect{
    border: 1px solid rgb(200,0,0);
}

.errorDiv .inputLabel, .errorDiv label, .errorSpan{
    color: rgb(200,0,0);
}

.pdfSelect {
    border: 1px solid #d2d2d2;
    padding: 8px 15px;
}

.pdfContainerDiv div, .pdfContainerDiv input, .pdfContainerDiv select{
    font-family: Quicksand, sans-serif;
    color: rgb(94,94,94);
    font-size: 16px;
}
.pdfContainerDiv label{
    font-family: Quicksand, sans-serif;
    color: rgb(27,27,27);
    font-size: 16px;
}

.sendButton {
    padding: 8px 60px;
    background-color: rgb(165,100,56);
    color: white;
    font-family: Quicksand, sans-serif;
    font-weight: 700px;
    font-size: 14px;
    border-radius: 4px;
}

.hidden {
    display:none;
}

.successh2 {
    font-size: 24px;
    color: rgb(0,128,0);
}

.successh3 {
    font-size: 16px;
}
